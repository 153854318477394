<template>
  <v-form @submit.prevent="submitQuestion" id="questionForm" ref="questionForm">
    <v-snackbar color="primary" v-model="snackbar">
      {{ snackbarText }}
    </v-snackbar>

    <v-container v-if="loading || superLoading">
      <v-row align="center" justify="center">
        <v-progress-circular
          class="mx-auto"
          indeterminate
          color="primary"
        ></v-progress-circular>
      </v-row>
    </v-container>

    <v-container v-else>
      <!-- if there are no question folders: Question Folders alert row -->
      <v-row
        justify="center"
        v-if="
          !loading &&
          !superLoading &&
          (questionFolders.length < 1 || courses.length < 1)
        "
      >
        <v-alert type="info" class="mt-4 mx-auto">
          We strongly recommend you to
          <v-btn to="/manage/question-bank" small>create folders</v-btn> and
          <v-btn to="/manage/courses" small>courses</v-btn> to better categorise
          your questions before creating a new one :)
        </v-alert>
      </v-row>

      <template v-else>
        <!-- Question Settings Row (w/ Select Course and folder dropdowns) -->
        <v-row justify="center">
          <v-card :loading="loading || superLoading" class="w-100 px-4 pb-4">
            <h1 class="text-h6 mt-3 blue--text text--darken-4">
              Question Settings
            </h1>
            <v-divider class="mt-2"></v-divider>

            <!-- Bi-lingual Question Checkbox -->
            <div class="d-flex align-center">
              <v-icon>mdi-chevron-right</v-icon>
              <span class="ml-1 mr-2"> Bi-lingual Question: </span>
              <v-checkbox
                v-model="isBilingual"
                :disabled="loading || superLoading"
                color="green"
                class="mt-5"
              ></v-checkbox>
              <small class="ml-3"
                >(Bug Warning: Do not turn on and then off again)</small
              >
            </div>

            <!-- Select Folder Dropdown -->
            <div class="d-flex align-center">
              <v-icon>mdi-chevron-right</v-icon>
              <span class="ml-1 mr-md-2"> Folder: </span>
              <v-select
                label="Select Folder"
                class="px-md-4 px-2 ml-1 text-capitalize"
                v-model="selectedFolder"
                :items="questionFolders"
                :disabled="loading || superLoading"
                required
              ></v-select>
            </div>

            <!-- Select Course Dropdown -->
            <div class="d-flex align-center">
              <v-icon>mdi-chevron-right</v-icon>
              <span class="ml-1 mr-md-2"> Course: </span>

              <v-select
                label="Select Folder"
                class="px-md-4 px-2 text-capitalize"
                v-model="selectedCourse"
                :items="courses"
                item-text="courseName"
                :disabled="loading || superLoading"
                required
              ></v-select>
            </div>
          </v-card>
        </v-row>

        <!-- Question Data Input Row -->
        <v-row justify="center" class="mt-6">
          <v-card :loading="loading || superLoading" class="w-100 px-4 pb-4">
            <!-- 'Create Question' Title -->
            <v-card-title class="justify-space-between">
              <h1 class="text-h6 mt-3 blue--text text--darken-4">
                Create Question
              </h1>
            </v-card-title>

            <v-divider class="mt-2"></v-divider>

            <v-container fluid>
              <!-- Question Rows -->
              <template v-for="(language, i) in languageModes">
                <v-row :key="language + '_' + i + '_question'">
                  <v-col cols="12" sm="12" class="d-flex justify-start title">
                    <span class="ml-2"
                      >Question <small>({{ language }} language)</small>:</span
                    >
                  </v-col>
                  <v-col cols="12" sm="12" class="d-flex justify-center title">
                    <editor
                      apiKey="gw9h6q955neydh9ys65lwymoyd9qxafiuskl5jzt8wkbt8zj"
                      model-events="change keydown blur focus paste keyup undo redo"
                      :init="tinymceInit"
                      v-model="question[language]"
                    ></editor>
                  </v-col>
                </v-row>
              </template>

              <v-divider class="mt-12"></v-divider>

              <!-- 'Answer Options' Title -->
              <v-row class="mt-5">
                <v-col
                  cols="12"
                  sm="12"
                  class="d-flex justify-start align-center title"
                >
                  <span class="ml-2"
                    >Answer Options:
                    <small
                      >( {{ options.length }} option(s) added.)</small
                    ></span
                  >
                </v-col>
              </v-row>

              <!-- make as many editors as the number of languages, for one option -->
              <template v-for="(option, optionIndex) in options">
                <v-col
                  cols="12"
                  sm="12"
                  class="my-5"
                  :key="option + '_' + optionIndex + '_deleteBtn'"
                >
                  <!-- Option Number and delete button -->
                  <v-divider></v-divider>
                  <div class="d-flex align-center">
                    Option :
                    <span class="title ml-2">{{ option }}</span>
                    <v-btn
                      @click="deleteOption(optionIndex)"
                      color="error"
                      class="ml-2 my-5"
                      :disabled="loading || superLoading"
                      icon
                      small
                      outlined
                      ><v-icon small>mdi-trash-can</v-icon></v-btn
                    >
                  </div>
                </v-col>

                <!-- Options Editor Rows -->
                <template v-for="language in languageModes">
                  <v-row
                    align="center"
                    :key="option + '_' + optionIndex + '_' + language"
                  >
                    <v-col
                      cols="12"
                      sm="2"
                      class="d-flex align-center mt-5"
                      :key="
                        option +
                        '_' +
                        optionIndex +
                        '_' +
                        language +
                        '_langLabel'
                      "
                    >
                      <i> {{ language }} language : </i>
                    </v-col>
                    <v-col cols="12" sm="10" class="d-flex justify-start title">
                      <editor
                        apiKey="gw9h6q955neydh9ys65lwymoyd9qxafiuskl5jzt8wkbt8zj"
                        model-events="change keydown blur focus paste keyup undo redo"
                        :init="tinymceInit"
                        v-model="optionsData[option][language]"
                      ></editor>
                    </v-col>
                  </v-row>
                </template>
              </template>

              <!-- Add option button -->
              <v-row align="center" class="mt-12">
                <v-btn
                  @click="addOption"
                  color="blue darken-4"
                  class="mx-auto"
                  :disabled="loading || superLoading"
                  dark
                  large
                >
                  <v-icon class="mr-2">mdi-plus</v-icon> Add Option</v-btn
                >
              </v-row>

              <v-divider class="my-9"></v-divider>

              <!-- Solution Rows -->
              <template v-for="(language, i) in languageModes">
                <v-row align="center" :key="language + '_' + i + '_solution'">
                  <v-col cols="12" sm="12" class="d-flex align-center">
                    <span class="title ml-2"
                      >Solution ({{ language }} language - optional) :</span
                    >
                  </v-col>
                  <v-col cols="12" sm="12" class="d-flex justify-center title">
                    <editor
                      apiKey="gw9h6q955neydh9ys65lwymoyd9qxafiuskl5jzt8wkbt8zj"
                      model-events="change keydown blur focus paste keyup undo redo"
                      :init="tinymceInit"
                      v-model="solution[language]"
                    ></editor>
                  </v-col>
                </v-row>
              </template>
            </v-container>
          </v-card>
        </v-row>

        <!-- Save Question Row (w/ correct answer select)-->
        <v-row justify="center" class="mt-6 mb-3">
          <v-card :loading="loading || superLoading" class="w-100 px-4 pb-4">
            <h1 class="text-h6 mt-3 blue--text text--darken-4">
              Save Question
            </h1>
            <v-divider class="mt-2"></v-divider>

            <!-- Error Row -->
            <v-row align="center" class="mt-7 mb-n3" no-gutters v-if="error">
              <v-col cols="12">
                <v-alert type="error">{{ error }}</v-alert>
              </v-col>
            </v-row>

            <!-- Correct Answer Select -->
            <div v-if="options.length > 0" class="d-flex align-center">
              <v-icon>mdi-chevron-right</v-icon>
              <span class="ml-1 mr-md-2"> Correct Answer: </span>
              <v-select
                label="Select Option"
                class="px-md-4 px-2 mt-7 text-capitalize"
                v-model="correctAnswer"
                :items="options"
                :disabled="loading || superLoading"
                dense
                outlined
              ></v-select>
            </div>

            <!-- Saved By Select -->
            <div v-if="options.length > 0" class="d-flex align-center">
              <v-icon>mdi-chevron-right</v-icon>
              <span class="ml-1 mr-md-2"> Created By: </span>
              <v-select
                label="Select Option"
                class="px-md-4 px-2 mt-7 text-capitalize"
                v-model="createdBy"
                :items="createdByOptions"
                item-value="value"
                item-text="text"
                :disabled="questionObj || loading || superLoading"
                dense
                outlined
              ></v-select>
            </div>

            <!-- Save Button -->
            <v-btn
              class="float-right mt-2"
              color="info"
              @click.prevent="submitQuestion"
              :disabled="loading || superLoading"
              large
            >
              <v-icon class="mr-2">mdi-content-save</v-icon> Save Question
            </v-btn>
          </v-card>
        </v-row>
      </template>
    </v-container>
  </v-form>
</template>

<script>
//TODO: Add uid specefic firestore rules for admin

const dayjs = require("dayjs");

import Editor from "@tinymce/tinymce-vue";

export default {
  name: "QuestionForm",
  props: ["superLoading", "questionObj"],
  components: {
    editor: Editor,
  },
  computed: {
    languageModes() {
      if (!this.isBilingual) {
        return ["Primary"];
      }
      return ["Primary", "Secondary"];
    },
  },

  data: () => ({
    //setup
    loading: true,
    snackbar: false,
    snackbarText: "",
    tinymceInit: {
      height: 240,
      menubar: false,
      branding: false,
      plugins: [
        "advlist autolink lists link image charmap",
        "searchreplace visualblocks code fullscreen",
        "print preview anchor insertdatetime media",
        "paste code help wordcount table codesample",
      ],
      // external_plugins: {
      //   tiny_mce_wiris: "https://www.wiris.net/demo/plugins/tiny_mce/plugin.js",
      // },

      // tiny_mce_wiris_formulaEditor tiny_mce_wiris_formulaEditorChemistry
      toolbar:
        "undo redo | formatselect | bold italic underline strikethrough subscript superscript forecolor |image link |table charmap | codesample | \
                          alignleft aligncenter alignright | \
                          bullist numlist outdent indent",
    },
    error: "",

    //question settings
    isBilingual: false,
    questionFolders: [], // get from server/store
    selectedFolder: "",
    courses: [], // get from server/store
    selectedCourse: "",

    //question Data
    question: {
      Primary: "",
      Secondary: "",
    },
    options: [1, 2, 3, 4],
    optionsData: {
      1: {
        Primary: "",
        Secondary: "",
      },
      2: {
        Primary: "",
        Secondary: "",
      },
      3: {
        Primary: "",
        Secondary: "",
      },
      4: {
        Primary: "",
        Secondary: "",
      },
    },
    solution: {
      Primary: "",
      Secondary: "",
    },
    correctAnswer: 0,

    createdByOptions: [
      {
        value: 1,
        text: "Kartik",
      },
      {
        value: 2,
        text: "Deepak",
      },
    ],
    createdBy: null,
  }),

  methods: {
    setLoading(value) {
      this.loading = value;
      this.$emit("setSuperLoading", value);
    },

    addOption() {
      const last_option = this.options[this.options.length - 1];
      var new_option = 1;

      //if last_option is a valid number: increment it
      if (!isNaN(last_option)) {
        new_option = last_option + 1;
      }

      // TODO: create languages object dynamically

      //create language structure in 'optionsData' for v-model of the option before creating option
      this.$set(this.optionsData, new_option, { Primary: "", Secondary: "" });

      this.options.push(new_option);
    },

    deleteOption(index) {
      if (this.options.length <= 1) {
        this.snackbarText = "Min. 1 option is required.";
        this.snackbar = true;
        return;
      }

      // remove from optionsData
      this.$delete(this.optionsData, this.options[index]);

      //remove from options array
      this.options.splice(index, 1);
    },

    isValidQuestionForm() {
      this.setLoading(true);

      const errorStrings = {
        noSelectedFolder: "Please select a folder.",
        noSelectedCourse: "Please select a course.",
        noQuestion: "Please input a question.",
        noQuestionBiLingual: "Please input a secondary language question.",
        noOptions: "PLease add at least 1 option.",
        noOptionsData: "Please input an option.",
        noOptionsDataBiLingual: "Please input a secondary language option.",
        noCorrectAnswer: "Please select a correct option.",
        noCreatedBy: "Please select a 'Created By' option.",
      };

      // selected Folder Check
      if (!this.selectedFolder) {
        this.error = errorStrings.noSelectedFolder;
        return false;
      }

      //selected Course check
      if (!this.selectedCourse) {
        this.error = errorStrings.noSelectedCourse;
        return false;
      }

      // Question Check
      if (!this.question.Primary) {
        this.error = errorStrings.noQuestion;
        return false;
      }

      // Secondary Question Check
      if (this.isBilingual) {
        if (!this.question.Secondary) {
          this.error = errorStrings.noQuestionBiLingual;
          return false;
        }
      }

      //min. options check
      if (this.options.length < 1) {
        this.error = errorStrings.noOptions;
        return false;
      }

      //options data check loop
      for (let key of Object.keys(this.optionsData)) {
        if (!this.optionsData[key].Primary) {
          this.error = errorStrings.noOptionsData + ` (Option ${key})`;
          return false;
        }
        if (this.isBilingual) {
          if (!this.optionsData[key].Secondary) {
            this.error =
              errorStrings.noOptionsDataBiLingual + ` (Option ${key})`;
            return false;
          }
        }
      }

      // no solution check req. because optional

      //correct answer check
      if (!this.correctAnswer) {
        this.error = errorStrings.noCorrectAnswer;
        return false;
      }

      //createdBy check
      if (!this.questionObj && !this.createdBy) {
        this.error = errorStrings.noCreatedBy;
        return false;
      }

      return true;
    },

    submitQuestion() {
      this.error = "";

      // Validate Editor Input
      if (!this.isValidQuestionForm()) {
        this.setLoading(false);
        return;
      }

      this.setLoading(true);

      const unix_timestamp_id = this.questionObj
        ? this.questionObj.id
        : `${dayjs().unix()}${this.createdBy}`;

      // submit increases respective counter, update does not
      const actionName =
        this.questionObj && this.questionObj.folder == this.selectedFolder
          ? "updateQuestion"
          : "submitQuestion";

      const payload = {
        id: `${unix_timestamp_id}`,
        isBilingual: this.isBilingual,
        question: this.question,
        options: this.options,
        optionsData: this.optionsData,
        correctAnswer: this.correctAnswer,
        solution: this.solution,
        folder: this.selectedFolder,
        course: this.selectedCourse,
      };

      this.$store
        .dispatch(actionName, payload)
        .then(() => {
          this.snackbarText = "Question saved successfully :)";

          // if old question is being edited AND the folder is changed
          if (
            this.questionObj &&
            this.questionObj.folder != this.selectedFolder
          ) {
            //decrease old folder count
            this.$store
              .dispatch("decrementFolderCount", this.questionObj.folder)
              .then(() => {
                this.setLoading(true);
              });
          } else {
            //this.$router.push("/home");
            this.setLoading(true);

            if (!this.questionObj) {
              this.resetQuestionForm();
            }
          }
        })
        .catch((error) => {
          this.error = error;
          this.snackbarText = "Error, please try again :(";
        })
        .finally(() => {
          this.snackbar = true;
          this.setLoading(false);
        });
    },

    resetQuestionForm() {
      this.setLoading(true);
      this.isBilingual = false;
      this.selectedCourse = "";
      this.$set(this.question, "Primary", "");
      this.$set(this.question, "Secondary", "");
      this.options = [1, 2, 3, 4];
      this.optionsData = {
        1: {
          Primary: "",
          Secondary: "",
        },
        2: {
          Primary: "",
          Secondary: "",
        },
        3: {
          Primary: "",
          Secondary: "",
        },
        4: {
          Primary: "",
          Secondary: "",
        },
      };
      this.$set(this.solution, "Primary", "");
      this.$set(this.solution, "Secondary", "");
      this.correctAnswer = 0;
      this.createdBy = null;
    },

    fetchFolders() {
      this.setLoading(true);

      //get folder names from server
      this.$store
        .dispatch("getQuestionFolders")
        .then((res) => {
          if (res) {
            this.questionFolders = res.folderNames;
          }
        })
        .catch(() => {
          this.error = "Network error in fetching folders, please try again.";
        })
        .finally(() => {
          this.setLoading(false);
        });
    },

    fetchCourses() {
      //get folder names from server
      this.$store
        .dispatch("getMetaDoc")
        .then((res) => {
          if (res) {
            this.courses = res.courses;
          }
        })
        .catch((err) => {
          this.error = err;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.setLoading(true);
    this.fetchCourses();
    this.fetchFolders();
    const questionObj = this.questionObj;
    if (questionObj) {
      this.setLoading(true);
      this.isBilingual = questionObj.isBilingual;
      this.selectedFolder = questionObj.folder;
      this.question = questionObj.question;
      this.options = questionObj.options;
      this.optionsData = questionObj.optionsData;
      this.solution = questionObj.solution;
      this.correctAnswer = questionObj.correctAnswer;
      this.selectedCourse = questionObj.course;

      if (questionObj.id.length > 10) {
        this.createdBy = this.createdByOptions[questionObj.id[10] - 1];
      }
    }
    this.setLoading(false);
  },
};
</script>
