<template>
  <div id="createQuestion" class="grey lighten-3 fill-height">
    <v-container>
      <v-row justify="center">
        <!-- title -->
        <h1 class="text-title my-9">
          <v-icon class="text-h3 mr-2" color="blue darken-4">
            mdi-text-box-plus
          </v-icon>
          Create Question
        </h1>
      </v-row>
    </v-container>
    <template v-if="!loading">
      <template v-if="localQuestionObj != null">
        <QuestionForm
          :questionObj="localQuestionObj"
          :superLoading="superLoading"
          @setSuperLoading="setSuperLoading"
        />
      </template>
      <template v-else>
        <QuestionForm
          :questionObj="questionObj"
          :superLoading="superLoading"
          @setSuperLoading="setSuperLoading"
        />
      </template>
    </template>
  </div>
</template>

<script>
import QuestionForm from "@/components/Home/Create/Question/QuestionForm.vue";
export default {
  name: "CreateQuestion",
  metaInfo: {
    title: "Create Question",
    meta: [
      {
        vmid: "description",
        name: "description",
        content: "Create Question Page description lorem ipsum dolor sit amet.",
      },
    ],
  },
  props: ["questionObj"],
  components: { QuestionForm },
  data: () => ({
    superLoading: true,
    loading: true,
    questionID: "",
    localQuestionObj: null,
  }),
  methods: {
    setSuperLoading(value) {
      this.superLoading = value;
    },
  },
  mounted() {
    const questionID = this.$route.params.id;
    // check valid pdfGroupIndex
    if (questionID && isNaN(questionID)) {
      this.$router.push("/404");
      return;
    }

    if (questionID) {
      this.$store
        .dispatch("getQuestion", questionID)
        .then((res) => {
          this.localQuestionObj = res;
          this.loading = false;
        })
        .catch((err) => console.log(err));
    } else {
      this.loading = false;
    }

    this.superLoading = false;
  },
};
</script>

<style>
.w-100 {
  width: 100%;
}
</style>